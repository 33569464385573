import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Img from 'gatsby-image'

const DinVagTillKorkortet = (props) => (
  <Layout>
    <Seo title='Din väg till körkortet' />
    <PageHeader text='Din väg till körkortet' />
    <div className='container'>
      <div className='row'>

        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>1: Ansök om körkortstillstånd</h2>
              <p>
                För att få börja övningsköra måste du ansöka om ett körkortstillstånd.<br />
                Ansökan sker hos transportstyrelsen och du måste ha fyllt 16 år för att kunna övningsköra.
                När du ska ansöka om ett körkortstillstånd krävs det att man gör en synundersökning och fyller i en hälsodeklaration.
                {/* Synundersökningen (synintyg) kan vi på trafikskolan hjälpa dig med. */}
              </p>
              <p className='mb-1'>
                <Link to='/information/korkortstillstand' className='btn btn-danger'> Läs mera om hur du beställer körkortstillstånd </Link>
              </p>
            </div>

            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center'>
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='ansök' className='rounded' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center imageShowBigScreen'>
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='Börja köra på Trafikskolan' className='rounded' />
            </div>

            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>2: Börja köra på Trafikskolan</h2>
              <p>
                Den första körlektionen bokar du in via receptionen.
                Antingen genom att ringa till receptionen eller genom att besöka oss på trafikskolan.
                Våra receptionister hjälper dig att ta det första steget och ge dig den information du behöver.
                Du kommer då bekräfta att dina personliga uppgifter stämmer samt godkänna våra avtalsvillkor.
                Vi följer dataskyddsförordningen GDPR.
              </p>
              <p className='mb-1'>
                <Link to='/info/avtalsvillkor' className='btn btn-danger'> Läs mera om våra avtalsvillkor </Link>
              </p>
            </div>

            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0  imageHideBigScreen'>
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='Börja köra på Trafikskolan' className='rounded' />
            </div>

          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>3: Börja köra hemma</h2>
              <p>
                När du beviljats körkortstillstånd får du börja köra hos oss på trafikskolan.
                <br /> <br />
                Observera att ett giltigt körkortstillstånd inte ger dig rätten att köra privat med handledare.
                För att du ska kunna få göra detta måste du och din privata handledare genomgå en handledarutbildning
                för att på så sätt tillgodose er ett handledarbevis.
              </p>
              <p className='mb-1'>
                <Link to='/utbildningar/handledarutbildning' className='btn btn-danger'> Läs mera om hur du bokar handledarutbildning </Link>
              </p>
            </div>

            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center'>
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt='Börja köra hemma' className='rounded' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center imageShowBigScreen'>
              <Img fluid={props.data.imageFour.childImageSharp.fluid} alt='Planering av körlektioner och teori' className='rounded' />
            </div>

            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>4: Planering av körlektioner och teori</h2>
              <p>
                När du kommer till oss första gången tar vi hänsyn till dina redan befintliga kunskaper vid första tillfället
                gå igenom vår utbildningsplan och diskutera vilka utbildningsinsatser som är bäst för dig.
                Om du har kört mycket hemma innan och det finns goda förkunskaper rekommenderar vi att man bokar in en testlektion.
                Vårt mål är att så tidigt och så snabbt som möjligt ge dig en bra uppskattning i både tid och kostnad för din utbildning.
              </p>

              <p className='mb-1'>
                <Link to='/utbildningar/personbilsutbildning' className='btn btn-danger'>Läs mera om vår personbilsutbildning </Link>
              </p>
            </div>
            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 imageHideBigScreen'>
              <Img fluid={props.data.imageFour.childImageSharp.fluid} alt='Planering av körlektioner och teori' className='rounded' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>5: Riskutbildningar del-1 och del-2</h2>
              <p>
                Riskutbildningen för B-körkort omfattar två delar och dessa är obligatoriska.
                Receptionen hjälper dig att planera in del 1 och del 2 i samband med din planering.
                Både riskutbildning del 1 och del 2 måste vara godkända innan ett sammanhållet förarprov
              </p>
              <ul>
                <li>Del 1 handlar om alkohol, andra droger, trötthet och riskfyllda beteenden i övrigt.</li>
                <li>Del 2 motsvarar den gamla riskutbildningen (halkbanan) och handlar om hastighet, säkerhet och körning under särskilda förhållanden</li>
              </ul>
              <p className='mb-1'>
                <Link to='/utbildningar/riskutbildningar' className='btn btn-danger'> Läs mera om riskutbildningarna här </Link>
              </p>
            </div>

            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center'>
              <Img fluid={props.data.imageFive.childImageSharp.fluid} alt='Riskutbildningar' className='rounded' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center imageShowBigScreen'>
              <Img fluid={props.data.imageSix.childImageSharp.fluid} alt='Utbildningskontroll' className='rounded' />
            </div>

            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>6: Utbildningskontroll</h2>
              <p>
                Mot utbildningens slut planeras en utbildningskontroll in.
                Utbildningskontrollens syfte är att låta en annan lärare (utbildningsledaren) göra en bedömning av elevens nuvarande körutbildning.
                Eleven kan se detta som en möjlighet att ”låsas att det är en uppkörning”. Läraren kommer under utbildningskontrollen vara mycket sparsam med att hjälpa eleven.
                I stället kommer instruktionerna handla mera vart eleven ska, elevens uppgift är att köra så självständig som möjligt utan hjälp.
              </p>
              <p>
                Utbildningskontrollen är ett sätt för lärarna att kunna jämföra om de tycker att eleven kommit tillräckligt lång i sin utbildning
                för en uppkörning eller om eleven behöver boka fler tider eller köra mera hemma.
                Efter avslutad utbildningskontroll diskuteras eventuella utbildningsinsatser utifrån resultatet av utbildningskontrollen
              </p>
            </div>
            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0  imageHideBigScreen'>
              <Img fluid={props.data.imageSix.childImageSharp.fluid} alt='Utbildningskontroll' className='rounded' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
              <h2>7: Kunskapsprovet och förarprovet</h2>
              <p>
                Proven utförs med 2-3 veckors mellanrum. Kunskapsprovet är den delen du gör först och kräver att riskutbildningarna är genomförda innan.
                Körprovet är den andra delen och kräver fr.o.m. 1/3 2020 att du har ett godkänt kunskapsprov innan du får genomföra det.
              </p>
              <p>
                När både kunskapsprov och körprov är godkänt så får du ditt körkort skickat till dig som rekommenderat brev inom 5 arbetsdagar!
              </p>
              <p className='mb-1'>
                <Link to='/information/kunskapsprov-och-korprov' className='btn btn-danger'> Läs mera om kunskapsprovet och förarprovet </Link>
              </p>
            </div>

            <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center'>
              <Img fluid={props.data.imageSeven.childImageSharp.fluid} alt='Kunskapsprovet och förarprovet' className='rounded' />
            </div>
          </div>
        </div>

        <div className='col-12 text-center my-5'>
          <Link to='/kontakta-oss/utbildningsanmalan' className='btn btn-danger'> Anmäl dig till vår körkortsutbildning här </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default DinVagTillKorkortet

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "information/din-vag-till-korkortet/11.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
 
  imageTwo: file(relativePath: { eq: "information/din-vag-till-korkortet/2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageThree: file(relativePath: { eq: "information/din-vag-till-korkortet/3.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageFour: file(relativePath: { eq: "information/din-vag-till-korkortet/4.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageFive: file(relativePath: { eq: "information/din-vag-till-korkortet/5.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageSix: file(relativePath: { eq: "information/din-vag-till-korkortet/6.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageSeven: file(relativePath: { eq: "information/din-vag-till-korkortet/7.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

} 

`
